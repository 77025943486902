<template>
    <div style=" width: auto; margin: 5px 0 0 0;">
        <v-navigation-drawer
            v-model="drawer"
            :mini-variant.sync="mini"      
            :width="selectedSidebarComponent === 'chartSharing' ? 500 : 314" height="92vh"
            mini-variant-width=37
            ref="navDrawer"
            style="background-color: #c7d5e7; border-bottom: 1px solid gray;  background-color: steelblue;"
            >      
            <div style=" width: auto; text-align: left; border-bottom: 1px solid gray;">

                <v-list-item @click.stop="toggleSidebar" class="px-2" style="background-color: steelblue; height: 5px; margin: 0 0 0 -5px">       

                    <v-btn icon>         
                        <v-icon>mdi-chevron-left</v-icon>
                    </v-btn>

                    <span v-if="mini === false" style="font-weight: bold; font-size: 1.5rem; text-align: center; color: white; margin: 0 0 0 4px">
                        <span>{{selectedSidebarTitle}}</span>
                    </span>

                </v-list-item>

                <div class="px-2" style="" v-bind:style="{ borderBottom: mini === true  ? '0px solid gray' : '1px solid gray',
                     backgroundColor: mini === true  ? '#c7d5e7' : '#EBEBEB'}">


                    <span v-if="$store.state.user.role === 'superadmin' && selectedSidebarComponent === 'endpoint'" style="text-align: center;">
                        <span style=""><v-icon v-if="selectedSidebarComponent === 'endpoint' && $store.state.user.role === 'superadmin'"  v-b-tooltip.hover.topleft="'endpoint'" 
                                               @click="showEndpointClicked" style="color: #404040;">mdi-access-point</v-icon></span>
                        <endpoint-selector style="margin: 3px 10px 30px 0px"></endpoint-selector>
                    </span>


                    <span v-if="selectedSidebarComponent === 'settings'" style="text-align: center;">
                        <span style=""><v-icon v-if="selectedSidebarComponent === 'settings'" v-b-tooltip.hover.topleft="'settings'" 
                                               @click="showSettingsClicked" style="color: #404040; margin-bottom: 8px">mdi-cog-outline</v-icon>
                        </span>
                                <div style="display: inline-flex; margin: 16px 0 15px 15px; padding-top: 5px">
                                    <div style="font-weight: bold; margin: 0px 0 0 0">symbols:&nbsp;</div>

                                    <b-form-select style="border: 1px solid gray; backgound-color: white; color: black;
                                                             margin-top: -7px; font-size: 12px"
                                                   v-model="symbols"
                                                   :options="symbolOptions"
                                                   size="sm"
                                                   ></b-form-select>
                                </div>
                    </span>

                    <span v-if="selectedSidebarComponent === 'chartSharing'">
                        <v-icon v-if="selectedSidebarComponent === 'chartSharing'" @click="showChartSharingClicked"  style="color: #404040;">mdi-share-circle</v-icon>
                        <div style=" width: 90%; margin: -23px 8px 0px 16px; border: 0px solid darkgray; font-size: 1.0rem; text-align: left;  color: rgb(7, 85, 163);">
                            <state-editor v-bind:namespace="activeModuleName"></state-editor>   
                        </div>

                    </span>   

                    <span v-if="selectedSidebarComponent === 'getStarted'" style="color: #0755A3;">
                        <v-icon v-if="selectedSidebarComponent === 'getStarted'" style="color: #404040;  margin-top: 4px" @click="showGetStartedClicked" id="v-step-1">
                            mdi-information-outline</v-icon>
                        <div class="card" style=" width: 270px; margin: -22px 8px 6px 26px; border: 1px solid darkgray; font-size: 1.1rem; text-align: left;  color: rgb(7, 85, 163);">
                            <div style="margin: 8px">                    
                                <span v-if="program === 'Tabs'">
                                    <div style="font-weight: bold; text-decoration: underline; text-align: left; margin-top: 0px;">Get Started</div>
                                    <ul id="features" style="font-weight: normal; margin-left: 10px">
                                        <li>Press the "+ add tab" button.</li>
                                        <li> Use the "Select Program" dropdown
                                            to change a tab or add a tab if there are none.</li>                                    
                                    </ul>

                                    <div style="font-weight: bold; text-decoration: underline; text-align: left; margin-top: 10px;">Features</div>
                                    <ul id="features" style="font-weight: normal; margin-left: 10px">
                                        <li>Tabs are automatically saved.</li>
                                        <li>Tabs can also be saved and organized by clicking the "Save" button below.</li>                                  
                                    </ul>

                                    <p style="margin-left: 0px;"><u><b>Troubleshooting</b></u> - If anything goes wrong just reload the page. If that doesn't help, please contact 
                                    <router-link to="/support" style="">Support</router-link>.
                                    </p>
                                </span>
                                <span v-if="program === 'TradeMaps'"><p><b>Seasonals</b> are great for showing the average changes of trades. But, if you want to see other measures,
                                        such as the probability of profit or the maximum adverse excursion, you need maps.</p><p> <b>These maps</b> were made by running the calculator on 
                                        every combination of open and close dates from now until the First Notice Date for the nearest open intracommodity trades with 1 or 2 legs. Notice that the longest trade is located at the upper-left corner
                                        of the map. You can see this by changing the <b>parameter to map</b> selector to "avg days".
                                        As each day passes, the first column on the left is removed. After expiration, a new map is generated.</p>
                                    <p><b>The bar chart and statistics</b> above the map help you to see the calculator statistics simultaneously so 
                                        you can find trades meeting your criteria faster.</p>
                                </span>
                                <span v-if="program === 'BasicCharts'" style="background-color: yellow; border: margin: 0px;"><p><b>These charts</b> show daily data and delayed data for the current day of most commodities.</p></span>
                                <span v-if="program === 'Calculator'" style="background-color: yellow;"><p><b>The table</b> shows the results of putting on the same trade every year with the same open and close.  In any given year, if the open date is not traded the program moves forward in time up to four days looking for a trade. If the close date is not traded, the program moves backward in time up to four days looking for a trade.</p>  
                                    <p><b>The chart</b> shows the seasonal coverage of the current and past trades. It is constructed by shifting lines in time (horizontally to the right) so that they all expire in the same year. The <span style="color: #d1b200">gold-shaded region</span> shows the selected trade period. Note that the series' labels show the shifted first and last dates, not the actual dates.</p>
                                </span>    
                                <span v-if="program === 'ForwardCurves'"><p><b>These charts</b> show the forward curves of the selected commodity for current
                                        and past years. If the market wasn't open with the current month and day in a previous year, the program moves back a day at a time
                                        until it finds an open.</p>
                                    <p><b>Normalization</b> shifts each curve vertically so that it begins at zero in order to make comparison easier.</p>
                                    <p>The <b>chart type</b> "aligned" shifts the curves horizontally so that they begin at the same time.</p></span>
                                <span v-if="program === 'OpenSpreads'"><b>These charts</b> show the next years' worth of open contracts (spreads) and are useful in determining which contracts are in backwardation and which are in contango and if any of these relationships have changed. This is helpful for finding spread opportunities as well for choosing the best month for an outright position (that is, a long or short position).</span>
                                <span v-if="program === 'Search'"><p><b>This database</b> was filled by running the calculator on every combination of open and close dates from now 
                                        until the First Notice Date for the nearest open intracommodity trades with 1 or 2 legs. Trades that had an 80% or greater historical 
                                        probability of profit for the last 15 years were added to the database.</p>
                                    <p>The search returns no more than one trade per ticker. This is the key to understanding how the search works. For each ticker, all the trades meeting your selection criteria are ranked according to the 
                                        <b style="color: red">sort by</b> selector and then chosen by the <b> direction</b> selector.</p>

                                    <div style="border: 0px solid gray; margin-left: 0px; margin-top: 8px; padding: 0px; border-radius: 3px; font-size: 1.0rem">
                                        <div style="white-space: nowrap"><b>mpe</b> = maximum profitable excursion</div>
                                        <div style="white-space: nowrap"><b>mae</b> = maximum adverse excursion</div>
                                        <div style="white-space: nowrap"><b>appd</b> = average profit per day</div>
                                    </div> 
                                </span>
                                <span v-if="program === 'SpreadCoverage'"><b>These charts</b> show when spreads traded.</span>
                                <span v-if="program === 'LongTermCharts'"><p>The <b>"unaligned"</b> option shows the previous spreads (or contracts) 
                                        as they occurred in time.</p>

                                    <p>The <b>"aligned"</b> option shifts the contracts (or spreads) in time so that they all expire in the same year. 
                                        The month and day of each data point remain unchanged - only the year has been changed.  This is actually 
                                        the first step in creating seasonals. These are also known as <b>"stacked  charts"</b>. 
                                        The <span style="color: black; font-weight: bold">black line</span> is the seasonal, which is the average of the 
                                        closed spreads. The seasonal and any open spreads are displayed with thick lines to help you identify them when the 
                                        spreads are aligned.</p>

                                    <p><b>Normalization</b> shifts each curve vertically so that it's value is zero at the specified date in order to make 
                                        comparison easier. Note that spreads not trading on the normalization date won't be plotted when
                                        normalization is on.</p>
                                </span>
                                <span v-if="program === 'StockCharts'"><b>Full-featured</b> stock and stock spread charts.</span>
                                <span v-if="program === 'History'">
                                    <b>Compare</b> a current position to previous years.
                                </span>
                            </div></div>         
                    </span>


                    <span v-if="selectedSidebarComponent === 'chartSaving'" style="margin: 0 0 0 0px">
                        <v-icon v-if="selectedSidebarComponent === 'chartSaving'" id="v-step-4"  style="color: #404040; margin-top: 4px" @click="showChartSavingClicked" >mdi-content-save-outline</v-icon>
                        <!-- <span style="font-weight: bold; font-size: 1.28rem; text-align: center; margin: 10px 0px -10px 75px; color: #404040">Saved Tabs</span> -->
                        <div class="card" style=" width: 250px; margin: -22px 8px 0px 26px; border: 1px solid darkgray; font-size: 1.0rem; text-align: left;  color: rgb(7, 85, 163);">
                            <div class="card-body">
                                <p class="card-text"><b>Use the input box</b> below to name and save your tabs. You can add folders and drag and drop the saved links into them to help you organize.</p>
                            </div>
                        </div>
                        <b-container fluid="xl" style="padding: 2px; margin: 2px 7px 2px 40px; ">
                            <b-form inline v-on:submit.prevent="onSaveChart" style="margin: 5px 5px 0 -16px">
                                <input size="sm" v-model="saveName" ref="saveName" type="text" class="form-control form-control-sm" id="__BVID__112" 
                                       style="border: 1px solid gray;font-size: 1.1rem; margin: 3px 3px 3px -1px; min-width: 200px;" placeholder="Insert text to save the tab">
                                <b-button v-if="saveShow" size="sm" variant="primary" placeholder="Insert text to save the chart"
                                          v-on:click="onSaveChart" style="background-color: #404040; color: white; font-size: 1.0rem; margin-right: 8px; border: 1px solid white;">Save</b-button>
                            </b-form>

                            <b-form-select v-if="$store.state.user.role === 'superadmin'" style="border:1px solid gray; margin: 4px 0px 0 -17px; width: 250px"
                                           v-model="userEmail"
                                           :options="usersOptions"></b-form-select> 

                            <div v-if="$store.state.user.role === 'superadmin'" style="text-align: center; margin: 4px 70px -15px 0">
                                <b-form-group label="" v-slot="{ ariaDescribedby }">
                                    <b-form-checkbox-group
                                        id="checkbox-group-1"
                                        v-model="selectedDisplayOptions"
                                        :options="displayOptions"
                                        :aria-describedby="ariaDescribedby"
                                        name="flavour-1"
                                        ></b-form-checkbox-group>
                                </b-form-group>
                            </div>

                            <div v-if="data !== null && selectedDisplayOptions.includes('tree')" 
                                 style="color: #0755A3;; background-color: white; margin: 2px 0px 2px -18px; border: 1px solid darkgray;
                                 font-size: 1rem; border-radius: 3px; width: 250px">
                                <b-button size="sm" variant="secondary" @click="addNode"
                                          style="margin: 4px; text-align: left; border: 1px solid darkgray; font-weight: bold; color: #0755A3;">Add Folder</b-button>
                                <vue-tree-list
                                    @click="onClick"
                                    @change-name="onChangeName"
                                    @update="onUpdate"
                                    @delete-node="onDel"
                                    @add-node="onAddNode"
                                    :model="data"
                                    default-tree-node-name="new folder"
                                    default-update-name="update database"
                                    default-leaf-node-name="new chart"
                                    v-bind:default-expanded="false"
                                    add-leaf-node-disabled = "true"
                                    style="font-size: 1.0rem; line-height: 1.1em; width: fit-content"
                                    id="left">
                                    <template v-slot:leafNameDisplay="slotProps">
                                        <span>
                                            {{ slotProps.model.name }} <span class="muted"></span>
                                        </span>
                                    </template>
                                    <span class="icon" slot="addTreeNodeIcon"><i class="far fa-folder" style="color: black; margin: 0 4px 0 0"></i></span>
                                    <span class="icon" slot="addLeafNodeIcon">＋</span>
                                    <span class="icon" slot="editNodeIcon"><i class="far fa-edit" style="color: black; margin: 1px 2px 1px 2px"></i></span>
                                    <span class="icon" slot="updateNodeIcon"><i class="fa fa-refresh" style="color: black; margin: 1px 2px 1px 2px"></i></span>
                                    <span class="icon" slot="delNodeIcon"><i class="far fa-trash-alt" style="color: black; margin: 1px 2px 1px 2px"></i></span>
                                    <span class="icon" slot="leafNodeIcon"></span>
                                    <span class="icon" slot="treeNodeIcon"><i class="far fa-folder" style="color: black; margin: 1px 2px 1px 2px"></i></span>
                                </vue-tree-list>
                            </div>

                            <div v-if="selectedDisplayOptions.includes('flat')" style="margin: 7px 0px 0 -18px">
                                <b-list-group v-for="(number, i) in sortedSavedCharts" v-bind:key="i">
                                    <b-list-group-item button v-on:click="onFlatClick(sortedSavedCharts[i].id)"
                                                       style="border:1px solid darkgray; color: black; width: 250px">{{sortedSavedCharts[i].saveName}}</b-list-group-item>
                                </b-list-group>
                            </div>


                        </b-container>
                    </span>
                </div>

                <v-list-item v-if="selectedSidebarComponent !== 'getStarted'" id="v-step-1" class="px-2" style="background-color: #c7d5e7;">
                    <v-icon v-b-tooltip.hover.topleft="'Program Info'" style="color: #404040;  padding: 0 0 4px 0;" @click="showGetStartedClicked">mdi-information-outline</v-icon>
                    <span @click="showGetStartedClicked" style="cursor: pointer; font-weight: bold; font-size: 1.3rem; margin: 0px 0px 4px 15px; color: #404040;">Program Description</span>
                </v-list-item>

                <v-list-item v-if="selectedSidebarComponent !== 'chartSaving'" id="v-step-4" class="px-2" style="background-color: #c7d5e7;">
                    <v-icon  v-b-tooltip.hover.topleft="'Saved Tabs'" style="color: #404040" @click="showChartSavingClicked">mdi-content-save-outline</v-icon>
                    <span @click="showChartSavingClicked" style="cursor: pointer;font-weight: bold; font-size: 1.3rem; text-align: center; margin: 0px 0px 0px 15px; color: #404040;">Saved Tabs</span>
                </v-list-item>

                <v-list-item  v-if="selectedSidebarComponent !== 'chartSharing' && $store.state.user.role === 'superadmin'" class="px-2" style="background-color: #c7d5e7; padding: 0 0 5px 0;">
                    <v-icon v-b-tooltip.hover.topleft="'Chart Sharing'" style="color: #404040" @click="showChartSharingClicked">mdi-share-circle</v-icon>
                    <span @click="showChartSharingClicked" style="cursor: pointer; font-weight: bold; font-size: 1.3rem; text-align: center; margin: 6px 0 0 15px; color: #404040;">Chart Sharing</span>
                </v-list-item>

                <v-list-item v-if="selectedSidebarComponent !== 'endpoint' && $store.state.user.role === 'superadmin'" class="px-2" style="background-color: #c7d5e7;">
                    <v-icon v-b-tooltip.hover.topleft="'endpoint'" style="color: #404040" @click="showEndpointClicked" >mdi-access-point</v-icon>
                    <span @click="showEndpointClicked" style="cursor: pointer; font-weight: bold; font-size: 1.3rem; margin: 0px 0px 0px 15px; color: #404040;">Endpoint</span>
                </v-list-item>

                <v-list-item v-if="selectedSidebarComponent !== 'settings'" class="px-2" style="background-color: #c7d5e7;">
                    <v-icon v-b-tooltip.hover.topleft="'settings'" style="color: #404040" @click="showSettingsClicked" >mdi-cog-outline</v-icon>
                    <span @click="showSettingsClicked" style="cursor: pointer; font-weight: bold; font-size: 1.3rem; margin: 8px 0px 0px 15px; color: #404040;">Settings</span>
                </v-list-item>
            </div>

            <!--<v-list dense style="background-color: #c7d5e7;">
                     <v-list-item
                       v-for="item in items"
                       :key="item.title"
                       link
                     >
                       <v-list-item-icon>
                         <v-icon>{{ item.icon }}</v-icon>
                       </v-list-item-icon>
             
                       <v-list-item-content>
                         <v-list-item-title>{{ item.title }}</v-list-item-title>
                       </v-list-item-content>
                     </v-list-item>
                   </v-list> -->
        </v-navigation-drawer>


        <!-- <saved-charts-search v-bind:namespace="namespace" 
                                       v-bind:index="'0'"></saved-charts-search> -->
    </div>
</template>

<style>
    ul#features li {
        margin-left:10px;
        /*  margin: -5px;*/
        list-style:disc;
    }

    .toggle {
        cursor: pointer;
    }

    .card-text {
        margin: -17px !important;
    }
</style>

<script>

    function sortFn(b, a) {
        // console.log("a=", a, " b=", b);
        let aIsFolder = !a.isLeaf;
        let bIsFolder = !b.isLeaf;
        //console.log("aIsFolder=", aIsFolder, " bIsFolder=", bIsFolder);

        if (aIsFolder && bIsFolder || !aIsFolder && !bIsFolder) {
            let returnObject = a.name.localeCompare(b.name);
            // console.log("returnObject=", returnObject);
            return returnObject;
        } else {
            return aIsFolder ? -1 : 1;
        }
    }

    function sortNodesAndChildren(nodes) {
        // console.log("sortNodesAndChildren() starting.");
        return nodes
                .sort(sortFn)
                .forEach(function (node) {
                    if (typeof node.children !== 'undefined' && node.children.length) {
                        sortNodesAndChildren(node.children);
                    }
                })
    }

    import Vue from 'vue';
    import { VueTreeList, Tree, TreeNode } from 'vue-tree-list';
    import * as fb from '@/firebase';
    // import savedChartsSearch from "@/components/saved-charts-search.vue";
    const StateEditor = () => import("@/components/state-editor");
    import endpointSelector from '@/components/endpoint-selector';

    Vue.use(VueTreeList);

    export default {
        components: {
            VueTreeList, StateEditor, endpointSelector
        },
        mounted() {
            // console.log("mounted() starting. this.namespace=", this.namespace);
            this.userEmail = this.$store.state.user.email;
            // this.updateSelectedSidebarComponentToFirebase();

            // console.log("this.selectedSidebarComponent=", this.selectedSidebarComponent);
            if (this.selectedSidebarComponent !== null) {
                this.mini = false;
            }
        },
        props: {
            namespace: {
                type: String
            },
            defaultAddTreeNodeTitle: {
                type: String,
                default: 'Add Folder'
            }
        },
        data: function () {
            return{
                json: "",
                saveName: "",
                hide: 'hide',
                show: 'show',
                saveShow: true,
                showState: false,
                savedCharts: [],
                selectedDisplayOptions: ["tree"],
                displayOptions: [
                    {text: 'tree view', value: 'tree'},
                    {text: 'flat view', value: 'flat'}
                ],
                // layout: [],
                oldName: "",
                newTree: {},
                data: null,
                initialized: false,
                users: [],
                userEmail: "",
                drawer: true,
                mini: true,
                sidebarOptions: [
                    {value: "getStarted", text: "Program Description"},
                    {value: "chartSaving", text: "Saved Tabs"},
                    {value: "chartSharing", text: "Chart Sharing"},
                    {value: "endpoint", text: "Endpoint"},
                    {value: "settings", text: "Settings"}
                ],
                symbolOptions: [
                    {value: "new", text: "new"},
                    {value: "old", text: "old"}
                ]
            }
        },

        firestore: {
            users: fb.usersCollection,
        },
        computed: {
            selectedSidebarTitle() {
                // console.log("this.selectedSidebarComponent=", this.selectedSidebarComponent);
                let option = this.sidebarOptions.find(x => x.value === this.selectedSidebarComponent);
                // console.log("option=", option);
                return typeof option !== 'undefined' ? option.text : "";
            },
            usersOptions() {
                return this.users.map(x => x.email);
            },
            email() {
                // console.log("this.$store.state.user.email=", this.$store.state.user.email);
                return this.$store.state.user.role === 'superadmin' ? this.userEmail : this.$store.state.user.email;
            },
            showStateEditor() {
                return this.$store.state.showStateEditor;
            },
            sortedSavedCharts() {
                return this.savedCharts.slice().sort(function (a, b) {
                    return (a.saveName > b.saveName) ? 1 : ((b.saveName > a.saveName) ? -1 : 0);
                });
            },
            selectedSidebarComponent: {
                get() {
                    return this.$store.state.user.selectedSidebarComponent;
                },
                set() {
                    // console.log("selectedSidebarComponent set() starting.");
                }
            },
            program() {
                // console.log("program=", this.$store.state[this.namespace].program);
                return typeof this.$store.state[this.namespace] === 'undefined' || typeof this.$store.state[this.namespace].program === 'undefined' ? "Tabs" : this.$store.state[this.namespace].program;
            },
            activeModuleName() {
                let activeModuleName = this.$store.getters['activeModuleName'];
                // console.log("activeModuleName=", activeModuleName);
                return activeModuleName;
            },
            symbols: {
                get() {
                    return this.$store.state.user.symbols;
                },
                set(symbols) {
                    //this.$store.commit("user/setSymbols", symbols);
                    fb.usersCollection.doc(this.email)
                            .update({"symbols": symbols})
                            .then(() => {
                                console.log('symbols updated.')
                            });

                }
            }
        },
        watch: {
            selectedSidebarComponent: function (selectedSidebarComponent) {
                // console.log("watch selectedSidebarComponent=", selectedSidebarComponent);
                if (selectedSidebarComponent !== null) {
                    this.mini = false;
                    this.selectedSidebarComponent = selectedSidebarComponent;
                } else {
                    this.mini = true;
                    this.selectedSidebarComponent = null;
                }
            },
            email: {
                immediate: true,
                handler(email) {
                    if (email !== "") {
                        fb.db.collection('saved-charts')
                                .doc(this.email).collection('layout').doc('layout-document')
                                .get()
                                .then((doc) => {
                                    if (doc.exists) {
                                        // console.log("Document data:", doc.data());
                                        let layout = doc.data().layout.slice();
                                        sortNodesAndChildren(layout);
                                        // console.log("layout=", layout);
                                        this.data = new Tree(layout);
                                        this.initialized = true;
                                    } else {
                                        // doc.data() will be undefined in this case
                                        console.log("No such document!");
                                        this.data = new Tree([/*{name: 'root', id: 1, pid: 0, children: []}*/]);
                                        this.initialized = true;
                                    }
                                }).catch((error) => {
                            console.log("Error getting document:", error);
                        });

                        if (email !== "") {
                            this.$bind('savedCharts', fb.db.collection('saved-charts').doc(email).collection('charts'));
                        }
                    }
                }
            },
            data: {
                handler(/*data*/) {
                    // console.log("watching data. this.oldName=", this.oldName + " this.initialized=", this.initialized);
                    if (this.oldName === "") {
                        if (this.initialized) {      // This is correct.
                            let layout = this.getNewTree().children;
                            if (typeof layout === 'undefined') {
                                layout = [];
                            }
                            // console.log("data=", data);
                            // console.log("layout=", layout);
                            fb.db.collection('saved-charts')
                                    .doc(this.email)
                                    .collection('layout')
                                    .doc('layout-document')
                                    .set({"layout": layout})
                                    .then(() => {
                                        // console.log('layout saved.')
                                    });
                        }
                    }
                },
                deep: true
            }
        },
        methods: {
            toggleSidebar() {
                console.log("toggleSidebar() starting.");
                if (this.mini === false) {
                    this.selectedSidebarComponent = null;
                    this.updateSelectedSidebarComponentToFirebase(null);
                    this.mini = true;
                } else {
                    this.mini = false;
                }
            },
            showSidebarComponentClicked(component) {
                console.log("showSidebarComponentClicked() starting. component=", component);
                let newSelectedSidebarComponent;
                if (this.selectedSidebarComponent === component) {
                    newSelectedSidebarComponent = null;
                    this.mini = true;
                } else {
                    newSelectedSidebarComponent = component;
                }
                this.updateSelectedSidebarComponentToFirebase(newSelectedSidebarComponent);
            },
            showGetStartedClicked() {
                this.showSidebarComponentClicked("getStarted");
            },
            showChartSharingClicked() {
                console.log("showChartSharingClicked() starting.");
                this.showSidebarComponentClicked("chartSharing");
            },
            showChartSavingClicked() {
                console.log("showChartSavingClicked() starting.");
                this.showSidebarComponentClicked("chartSaving");
            },
            showEndpointClicked() {
                console.log("showEndpointClicked() starting.");
                this.showSidebarComponentClicked("endpoint");
            },
            showSettingsClicked() {
                console.log("showSettingsClicked() starting.");
                this.showSidebarComponentClicked("settings");
            },
            /*  toggleShowGetStarted() {
             console.log("toggleShowGetStarted() starting. this.showGetStarted=", this.showGetStarted + " this.email=", this.email);
             fb.usersCollection
             .doc(this.email)
             .update({"showGetStarted": !this.showGetStarted})
             .then(() => {
             console.log('showGetStarted updated.');
             });
             }, */
            updateSelectedSidebarComponentToFirebase(component) {
                console.log("updateSelectedSidebarComponentToFirebase() starting. component=", component);
                fb.usersCollection
                        .doc(this.email)
                        .update({"selectedSidebarComponent": component})
                        .then(() => {
                            console.log('selectedSidebarComponent updated.');
                        }).catch((error) => {
                    // The document probably doesn't exist.
                    console.error("Error updating document: ", error);
                });
            },
            getNewTree: function () {
                // console.log("getNewTree() starting.");
                let vm = this
                function _dfs(oldNode) {
                    // console.log("oldNode=", oldNode);

                    let newNode = {}

                    for (let k in oldNode) {
                        if (k !== 'children' && k !== 'parent') {
                            newNode[k] = oldNode[k]
                        }
                    }
                    // console.log("newNode=", newNode);

                    if (oldNode.children && oldNode.children.length > 0) {
                        newNode.children = []
                        for (let i = 0, len = oldNode.children.length; i < len; i++) {
                            newNode.children.push(_dfs(oldNode.children[i]))
                        }
                    }
                    return newNode
                }

                // vm.newTree = _dfs(vm.data);
                return _dfs(vm.data);
            },
            onDel(node) {
                console.log("onDel() starting. node=", node);
                if (node.isLeaf) {
                    if (node.name !== "") {
                        this.removeChart(node.id);
                        node.remove();
                    }
                } else {
                    if (node.children !== null) {
                        let numberOfChildren = node.children.length;
                        console.log("numberOfChildren=", numberOfChildren);
                        if (numberOfChildren === 0) {
                            node.remove();
                        } else {
                            alert("You can only delete empty folders.");
                        }
                    } else {
                        node.remove();
                    }
                }
            },
            onUpdate(node) {
                console.log("onUpdate() starting. node=", node);
                let generalForm = JSON.parse(JSON.stringify(this.$store.state[this.namespace]));
                // let saveName = generalForm.saveName;
                let parent = node.parent;

                const {name} = node;
                console.log("parent=", parent);

                this.saveChart(name, generalForm)
                        .then(result => {
                            let newNode = new TreeNode({name: name, isLeaf: true, addLeafNodeDisabled: true, id: result.id})
                            if (parent !== null) {
                                if (!parent.children) {
                                    parent.children = [];
                                }
                                parent.addChildren(newNode);
                                this.onDel(node);
                            }
                        });
            },
            onChangeName(params) {
                console.log("onChangeName() starting. params=", params, " this.oldName=", this.oldName);
                if (this.oldName === "") {
                    this.oldName = params.oldName;
                }
                if (typeof params.eventType !== 'undefined') {
                    console.log("Changing name.");
                    console.log("this.oldName=", this.oldName);

                    if (params.isLeaf) {
                        fb.db.collection("saved-charts").doc(this.email).collection("charts").doc(params.id)
                                .update({saveName: params.newName})
                                .then(() => {
                                    console.log("Chart successfully updated!");
                                    this.oldName = "";
                                })
                                .catch((error) => {
                                    // The document probably doesn't exist.
                                    console.error("Error updating document: ", error);
                                });

                        let layout = this.getNewTree().children;
                        if (typeof layout === 'undefined') {
                            layout = [];
                        }
                        // console.log("layout=", layout);
                        fb.db.collection('saved-charts').doc(this.email).collection('layout').doc('layout-document')
                                .set({"layout": layout})
                                .then(() => {
                                    console.log('layout saved.')
                                });
                    } else {
                        let layout = this.getNewTree().children;
                        if (typeof layout === 'undefined') {
                            layout = [];
                        }
                        // console.log("layout=", layout);
                        fb.db.collection('saved-charts')
                                .doc(this.email).collection('layout').doc('layout-document').set({"layout": layout})
                                .then(() => {
                                    console.log('layout saved.');
                                    this.oldName = "";
                                });
                    }
                }
            },

            onAddNode(params) {
                console.log("onAddNode() starting. params=", params);
                params.addLeafNodeDisabled = true;
            },
            addLeafHighlight(id) {
                // console.log("addLeafHighlight() starting.");
                document.getElementById(id).style.fontWeight = "bold";
                document.getElementById(id).style.color = "blue";
                document.getElementById(id).classList.add("highlightedLeaf");

                let updateNode = document.getElementById(id).getElementsByClassName("fa fa-refresh")[0];
                // console.log("updateNode=", updateNode);
                updateNode.style.visibility = "visible";

            },
            removeLeafHighlight() {
                // console.log("removeLeafHighlight() starting.");
                let highlightedLeaf = document.getElementsByClassName("highlightedLeaf");
                // console.log("highlightedLeaf=", highlightedLeaf);

                if (highlightedLeaf.length > 0) {
                    highlightedLeaf.forEach(x => {
                        // console.log(" x=", x);
                        x.style.fontWeight = "normal";
                        x.style.color = "#0755A3";
                        x.classList.remove("highlightedLeaf");

                        let updateNode = x.getElementsByClassName("fa fa-refresh")[0];
                        // console.log("updateNode=", updateNode);
                        updateNode.style.visibility = "hidden";
                    })
                }
            },
            onClick(params) {
                // console.log("onClick() starting. params=", params);
                //  console.log("params.editable=", params.editable);
                this.removeLeafHighlight();

                if (params.isLeaf) {
                    this.addLeafHighlight(params.id);
                    if (!params.editable) {


                        this.getChart(params.id)
                                .then(chart => {
                                    // console.log("chart=", chart);
                                    this.$emit('newTabWithInitialState', chart);
                                });
                    }
                }
            },
            onFlatClick(id) {
                // console.log("onFlatClick() starting. id=", id);
                this.getChart(id)
                        .then(chart => {
                            console.log("chart=", chart);
                            this.$emit('newTabWithInitialState', chart);
                            this.saveName = chart.saveName;
                        });
            },
            addNode() {
                //  console.log("addNode() starting.");
                let node = new TreeNode({name: 'new folder', isLeaf: false, addLeafNodeDisabled: true})
                if (!this.data.children) {
                    this.data.children = [];
                }
                this.data.addChildren(node);
            },
            addLeaf(name, id) {
                // console.log("addLeaf() starting. name=", name, " this.data=", this.data, " id=", id);
                if (name !== "") {
                    let node = new TreeNode({name: name, isLeaf: true, addLeafNodeDisabled: true, id: id})
                    if (this.data !== null) {
                        if (!this.data.children) {
                            this.data.children = [];
                        }
                        this.data.addChildren(node);
                    }
                }
            },
            onSaveChart() {
                console.log("onSaveChart() starting.");
                if (this.saveName !== "") {
                    let generalForm = JSON.parse(JSON.stringify(this.$store.state[this.namespace]));
                    this.saveChart(this.saveName.slice(), generalForm)
                            .then(result => {
                                this.addLeaf(this.saveName, result.id);
                                this.saveName = "";
                            });
                } else {
                    alert("Please enter a name for this.");
                }
            },
            saveChart: function (saveName, generalForm) {
                console.log("saveChart() starting.");

                return new Promise((resolve) => {
                    let language = "en";
                    console.log("saveName=" + saveName);

                    language = "en";
                    console.log("language=" + language);
                    let subscribersOnlyMessage, enterNameMessage;
                    if (language == "en") {
                        subscribersOnlyMessage = "Only subscribers can save charts.";
                        enterNameMessage = "Please enter a name for this chart.";
                    } else if (language == "de") {
                        subscribersOnlyMessage = "Nur Abonnenten kÃ¯Â¿Â½nnen Diagramme speichern.";
                        enterNameMessage = "Bitte geben Sie einen Namen fÃ¯Â¿Â½r diese Tabelle ein.";
                    } else if (language == "es") {
                        subscribersOnlyMessage = "SÃ¯Â¿Â½lo los suscriptores pueden guardar grÃ¯Â¿Â½ficos.";
                        enterNameMessage = "Introduzca un nombre para esta tabla.";
                    }

                    if (this.email == "" || this.email == "guest") {
                        alert(subscribersOnlyMessage);
                    } else if (saveName == "") {
                        alert(enterNameMessage);
                    } else {
                        delete generalForm.browserSideOnly;
                        // delete generalForm.playback;
                        generalForm.saveName = saveName;
                        //    generalForm.language = $('#language').val();
                        console.log("generalForm", generalForm);

                        fb.db.collection('saved-charts')
                                .doc(this.email)
                                .collection('charts')
                                .add(generalForm)
                                .then((docRef) => {
                                    console.log("Document written with ID: ", docRef.id);
                                    console.log('chart saved.');
                                    resolve({id: docRef.id});
                                });
                    }
                });
            },
            removeChart: function (id) {
                console.log("removeChart() starting. id=", id);
                fb.db.collection("saved-charts").doc(this.email).collection("charts").doc(id).delete().then(() => {
                    console.log("chart deleted.");

                    let layout = this.getNewTree().children;
                    sortNodesAndChildren(layout);
                    // console.log("layout=", layout);
                    this.data = new Tree(layout);
                }).catch((error) => {
                    console.error("Error removing document: ", error);
                });
            },
            getChart: function (id) {
                //  console.log("getChart() starting. id=", id);
                return new Promise((resolve) => {
                    fb.db.collection("saved-charts").doc(this.email).collection("charts").doc(id).get()
                            .then((doc) => {
                                if (doc.exists) {
                                    // console.log("Document data:", doc.data());
                                    resolve(doc.data());
                                } else {
                                    // console.log("No such document!");
                                    resolve("No such document!");
                                }
                            }).catch((error) => {
                        console.log("Error getting document:", error);
                    });
                });
            }
        }
    }
</script>
